import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const serviceApi = createApi({
  reducerPath: 'serviceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  endpoints: (builder) => ({
    getServices: builder.query({
      query: () => 'service',
    }),
    addTask: builder.mutation({
      query: (body) => ({
        url: 'task/add',
        method: 'POST',
        body,
      }),
    }),
    getService: builder.query({
      query: (id) => `service/${id}`,
    }),
  }),
});

export const { useGetServicesQuery, useAddTaskMutation, useGetServiceQuery } = serviceApi;
export default serviceApi;
