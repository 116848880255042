const advantages = [
  {
    id: 1,
    title: 'Эксклюзивный дизайн и стиль',
    description:
      'Мы создаем уникальные дизайны, которые отражают индивидуальность вашего бренда и выделяют его на фоне конкурентов. Наша команда дизайнеров разработает для вас эксклюзивный стиль, который запомнится вашим клиентам.',
  },
  {
    id: 2,
    title: 'Персональный подход',
    description:
      'Мы учитываем индивидуальные потребности каждого клиента и разрабатываем решения, соответствующие их целям и ожиданиям. Наша команда готова выслушать ваши пожелания и превратить их в реальность.',
  },
  {
    id: 3,
    title: 'Ведущие технологии',
    description:
      'Мы всегда следим за последними технологическими тенденциями и используем передовые разработки для создания инновационных решений. Наша команда экспертов готова предложить вам самые современные технологии.',
  },
  {
    id: 4,
    title: ' Обслуживание и поддержка',
    description:
      'Мы ценим каждого клиента и обеспечиваем круглосуточную поддержку на всех этапах работы. Наша команда готова помочь вам с любыми вопросами или проблемами в любое время дня и ночи.',
  },
  {
    id: 5,
    title: 'Гарантия качества',
    description:
      'Мы гарантируем высокое качество нашей работы и стремимся к полному удовлетворению потребностей клиентов. Ваш успех - наша цель, и мы делаем все возможное, чтобы достичь его вместе с вами.',
  },
];

export default advantages;
