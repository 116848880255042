import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openItem: null,
  menu: [
    {
      id: 'portfolio',
      title: 'Портфолио',
      link: '/portfolio',
    },
    // {
    //   id: 'feedback',
    //   title: 'Отзывы',
    //   link: '/feedback',
    // },
    // {
    //   id: 'blog',
    //   title: 'Блог',
    //   link: '/blog',
    // },
    {
      id: 4,
      title: 'Контакты',
      link: 'contact',
    },
  ],
};

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setOpenItem(state, action) {
      state.openItem = action.payload;
    },
  },
});

export const { setOpenItem } = menu.actions;

export default menu.reducer;
