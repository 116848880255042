import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';
import { useGetUsersQuery } from 'store/reducers/usersApi';

function TeamCard({ id }) {
  const { data = [], isFetching } = useGetUsersQuery();
  if (isFetching) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="300px" width="100%">
        <CircularProgress />
      </Box>
    );
  }
  if (!data.length) return null;
  const author = data.find((item) => item.id === id);
  return (
    <div className="ourteam-item row m-0 ">
      <div className="img col-lg-4 p-0">
        {author?.avatar ? (
          <img
            className="pointer-all"
            loading="lazy"
            src={author.avatar}
            alt="avatar"
            style={{ width: '100%', height: '100%' }}
          />
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: 'primary.main',
              borderRadius: '50%',
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h1" color="textSecondary" textTransform="uppercase">
              {author.first_name ? author.first_name.charAt(0) : author.email.charAt(0)}
            </Typography>
          </Box>
        )}
      </div>
      <div className="info w-100">
        <h6 className="dsc1 pointer-all">
          {author.first_name && author.last_name ? (
            <>
              <span>{author.first_name}</span> <span>{author.last_name}</span>
            </>
          ) : (
            author.username
          )}
        </h6>
        <p className="paragraph" style={{ lineHeight: '1.2' }}>
          {author.position}
        </p>
      </div>
    </div>
  );
}

export default TeamCard;
