import React from 'react';
import './style.css';
import PortfolioCard from 'components/intropage/portfolio/PortfolioCard';
import { useGetProjectQuery } from 'store/reducers/portfolio';

import { Box, CircularProgress } from '@mui/material';

function PorrtfoliosPage() {
  const { data: portfoliolist = [], isLoading } = useGetProjectQuery();

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100%">
        <CircularProgress />
      </Box>
    );
  }
  const subArrayLength = 3;
  const sortedList = [...portfoliolist].sort((a, b) => a.index_number - b.index_number);
  const portfolios = Array.from(
    { length: Math.ceil(sortedList.length / subArrayLength) },
    (_, index) => sortedList.slice(index * subArrayLength, index * subArrayLength + subArrayLength),
  );

  return (
    <section className="portfolios_page pt-5">
      <h2 className="headline2 pointer-all">Портфолио</h2>
      <div className="portfolios_container">
        {portfolios.map((projects) => (
          <div key={projects[0]?.id}>
            <div className="portfolio-cards">
              {projects.slice(0, 2).map((item) => (
                <PortfolioCard
                  key={item.id}
                  data={item}
                  type={projects.slice(0, 2).length === 1 ? 'single' : 'group'}
                />
              ))}
            </div>
            <div className="portfolio-cards" style={{ marginTop: '20px' }}>
              <PortfolioCard data={projects[2]} type="single" />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default PorrtfoliosPage;
