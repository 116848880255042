import { Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './carousel.css';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';

const SwiperComponent = ({ data, bg }) => {
  const [loadingStates, setLoadingStates] = useState(new Array(data.length).fill(true)); // Array to manage loading state for each photo

  if (!data) {
    return null;
  }
  const handleImageLoad = (id) => {
    setLoadingStates((prevLoadingStates) => {
      const newLoadingStates = [...prevLoadingStates];
      newLoadingStates[id] = false;
      return newLoadingStates;
    });
  };
  const slides = [];
  if (data.length < 3) {
    const item = data;
    const side = (
      <Box
        sx={{ py: { xs: 4, md: 6 }, backgroundColor: bg, borderRadius: 3, minWidth: '100%' }}
        width={'100%'}
        overflow={'hidden'}
        height={'100%'}
      >
        <Box
          height={'100%'}
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          px={{ xs: 1, sm: 3, md: 10 }}
        >
          {item.map((sub, index) => {
            return (
              <Box
                flex={'1 1 0'}
                key={index}
                height={'100%'}
                width={'fit-content'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                px={{ xs: 0.5, sm: 1 }}
              >
                {loadingStates[sub.id] && (
                  <Box className="carousel-loader" sx={{ borderRadius: 2, width: '100%' }} />
                )}
                <img
                  onLoad={() => handleImageLoad(sub.id)}
                  src={sub.upload}
                  alt="carousel"
                  className="carousel-img"
                  style={{
                    objectFit: 'cover',
                    display: loadingStates[sub.id] ? 'none' : 'block',
                  }}
                />
                <Typography fontWeight={'200'} variant="caption" textAlign={'center'}>
                  {sub?.title}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
    slides.push(side);
  } else {
    for (let i = 0; i < data.length; i += 3) {
      const item = data.sort((a, b) => a.index_number - b.index_number).slice(i, i + 3);
      const slide = (
        <SwiperSlide key={i} style={{ backgroundColor: bg, borderRadius: 20, minWidth: '100%' }}>
          <Box sx={{ py: { xs: 4, md: 6 } }} width={'100%'} overflow={'hidden'} height={'100%'}>
            <Box
              height={'100%'}
              width={'100%'}
              display={'flex'}
              justifyContent={'center'}
              px={{ xs: 1, sm: 3, md: 10 }}
            >
              {item.map((sub, index) => {
                return (
                  <Box
                    flex={'1 1 0'}
                    key={index}
                    height={'100%'}
                    width={'fit-content'}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    px={{ xs: 0.5, sm: 1 }}
                  >
                    {loadingStates[sub.id] && (
                      <Box className="carousel-loader" sx={{ borderRadius: 2, width: '100%' }} />
                    )}
                    <img
                      onLoad={() => handleImageLoad(sub.id)}
                      src={sub.upload}
                      alt="carousel"
                      className="carousel-img"
                      style={{
                        objectFit: 'cover',
                        display: loadingStates[sub.id] ? 'none' : 'block',
                      }}
                    />
                    <Typography fontWeight={'200'} variant="caption" textAlign={'center'}>
                      {sub?.title}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </SwiperSlide>
      );
      slides.push(slide);
    }
  }
  return (
    <Box height="min-content pt-2" width="100%">
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={0}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
      >
        {slides}
      </Swiper>
    </Box>
  );
};

export default SwiperComponent;
