import React from 'react';
import './style.css';
import { useParams } from 'react-router-dom';
import PortfolioCard from 'components/intropage/portfolio/PortfolioCard.jsx';
import { useGetServiceQuery } from 'store/reducers/serviceApi';
import { useGetProjectQuery } from 'store/reducers/portfolio';

import { Box, CircularProgress } from '@mui/material';

function ServicePage() {
  const { id } = useParams();
  const { data: service = {}, isFetching } = useGetServiceQuery(id);
  const { data: portfolio = [], isFetching: portfolioFetching } = useGetProjectQuery(id);
  if (portfolioFetching || isFetching) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100%">
        <CircularProgress />
      </Box>
    );
  }

  const length = portfolio.slice(0, 5).length;
  const isTooLong = service.title.includes('3D моделирование');
  const title = service?.title.replace(/(3D моделирование)/g, '<div>$1</div>');
  return (
    <section className="service-page">
      <div className="heading">
        <h2
          className={'headline2 pointer-all' + (isTooLong ? ' too-long' : '')}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p className="quote pointer-all">{service?.description}</p>
      </div>

      <div className="content service_page-content">
        <div className="left-side">
          <h4
            className={
              'child pointer-all pb-4 pb-lg-0 ' +
              (service?.goals_objectives_text.toUpperCase().includes('АУДИТ')
                ? 'headline3'
                : 'dsc1')
            }
          >
            {service?.goals_objectives_text}
          </h4>
        </div>
        <div className="right-side">
          <div className="sub_text">
            <div>
              <h1
                className={
                  'content_heading ' +
                  (service?.title_sec1.toUpperCase().includes('АУДИТ')
                    ? 'headline4 bold'
                    : 'headline3')
                }
              >
                {service?.title_sec1}
              </h1>
            </div>
            <p className="paragraph py-4 pointer-all">{service?.content_sec1}</p>
            <h2 className="artcile__info headline4 pointer-all">{service?.title_sec2}</h2>
            <p className="paragraph py-4 pointer-all">{service?.content_sec2}</p>
          </div>

          <div className="content_heading pt-5 pb-4 sub_text">
            <h1 className="headline3 pointer-all">{service?.title_process}</h1>
          </div>
          <div className="develope_steps">
            {service?.processes
              .map((item) => JSON.parse(item))
              .map((item, i) => (
                <div key={item.id} className="step">
                  <div className="step-text">
                    <div className="headline4 step-title pointer-all">{item.title}</div>
                    <p className="paragraph pointer-all">{item.content}</p>
                  </div>
                  <div className="step-number">
                    <h3 className="headline3 pointer-all">{item.index_number}</h3>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="content service_page-content">
        <div className="left-side">
          <h4 className="headline4 pointer-all">{service?.title_present_project}</h4>
        </div>
        <div className="right-side">
          {service?.mini_text.split('\n').map((paragraph, index) => (
            <p className="paragraph sub_text pointer-all" key={index}>
              {paragraph}
            </p>
          ))}
          {service?.photo_present_project && (
            <div className="admin">
              <img loading="lazy" src={service?.photo_present_project} alt="admin" />
            </div>
          )}
          {length > 0 && (
            <>
              <div className="content_heading pt-5 mt-2">
                <h3 className="headline3 pointer-all pt-5">Реализованные проекты</h3>
              </div>
              <div className="portfolio-cards pt-5 ">
                {portfolio.slice(0, 5).map((item, i) => {
                  const isOdd = length % 2 === 1;
                  const type = i === length - 1 && isOdd ? 'single' : 'group';
                  return <PortfolioCard key={item.id} data={item} type={type} />;
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default ServicePage;
