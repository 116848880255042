import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as ScrollButton } from 'react-scroll';

// css
import './header.css';

// components
import BtnOutline from 'components/utils/btn/Button1';

// redux
import { setOpenItem } from 'store/reducers/menu';
import Logo from 'images/Logo';

function Header() {
  const { pathname } = useLocation();
  const { menu, openItem } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const hideCollapse = (e) => {
    const nav = document.getElementById('navbarResponsive');
    if (nav.classList.length === 2) {
      const btn = document.getElementById('collapseButton');
      if (btn) {
        btn.click();
      }
    }
  };
  useEffect(() => {
    const openMenu = menu.find((item) => pathname.includes(item.link));
    dispatch(setOpenItem(openMenu?.id || null));
  }, [pathname, menu, dispatch, openItem]);

  useEffect(() => {
    const handleResize = () => {
      const nav = document.getElementById('navbarResponsive');
      if (nav && window.innerWidth > 997) {
        const btn = document.getElementById('collapseButton');
        if (btn && nav.classList.length === 2) {
          nav.style.display = 'none';
        }
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="header pointer-all">
        <header className="text-white navbar navbar-expand-lg" onClick={hideCollapse}>
          <div className="container">
            <Link
              to="/"
              className="d-flex align-items-center text-white text-decoration-none mr-3 header-logo"
            >
              <Logo />
            </Link>
            <button
              style={{ color: '#fff' }}
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarResponsive"
              aria-expanded="false"
              aria-controls="navbarResponsive"
              id="collapseButton"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="currentColor"
                className="bi bi-list"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                />
              </svg>
            </button>
            <div className="collapse navbar-collapse" id="navbar">
              <ul className="nav gap-2">
                {menu.map((item) => (
                  <li key={item.id}>
                    {item.link === 'contact' ? (
                      <ScrollButton to={item.link} spy smooth duration={500}>
                        <span className="nav-link">{item.title}</span>
                      </ScrollButton>
                    ) : (
                      <Link
                        to={item.link}
                        className={
                          'nav-link nav' + item.id + (openItem === item.id ? ' active-link' : '')
                        }
                      >
                        {item.title}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
              <div className="text-end">
                <ScrollButton to="contact" duration={500} smooth spy offset={-100}>
                  <BtnOutline>Заказать проект</BtnOutline>
                </ScrollButton>
              </div>
            </div>
          </div>
        </header>
        <div className="collapse" id="navbarResponsive">
          <div className="navbar-modal">
            <ul>
              {menu.map((item) => (
                <li key={item.id}>
                  {item.link === 'contact' ? (
                    <ScrollButton offset={-100} to={item.link} spy smooth duration={500}>
                      <span onClick={hideCollapse} className="nav-link">
                        {item.title}
                      </span>
                    </ScrollButton>
                  ) : (
                    <Link
                      to={item.link}
                      className={
                        'nav-link nav' + item.id + (openItem === item.id ? ' active-link' : '')
                      }
                      onClick={hideCollapse}
                    >
                      {item.title}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
            <div className="text-end">
              <ScrollButton to="contact" smooth spy offset={-100} duration={800}>
                <span onClick={hideCollapse}>
                  <BtnOutline>Заказать проект</BtnOutline>
                </span>
              </ScrollButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
