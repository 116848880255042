import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import 'particles.js';

function ParticlesComponent() {
  const { pathname } = useLocation();
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: pathname.includes('/portfolio/') ? 15000 : 7000,
  });

  useEffect(() => {
    const particlesJS = window.particlesJS;
    particlesJS('particles-js', {
      particles: {
        number: {
          value: 80,
          density: {
            enable: true,
            value_area: 1100,
          },
        },
        size: {
          value: 6,
          random: true,
        },
        opacity: {
          value: 0.2,
        },
        move: {
          speed: 3,
          out_mode: 'bounce',
        },
        line_linked: {
          opacity: 0.5,
          width: 1,
          distance: 130,
        },
      },
      interactivity: {
        evnts: {
          onclick: {
            enable: false,
            mode: 'grab',
          },
        },
      },
    });
  }, [pathname]);
  useEffect(() => {
    setScreenSize({
      width: window.innerWidth,
      height: pathname.includes('/portfolio/') ? 15000 : 7000,
    });
  }, [pathname]);
  useEffect(() => {
    const handleResize = () => {
      setScreenSize((s) => ({
        ...s,
        width: window.innerWidth,
      }));
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      id="particles-js"
      style={{
        top: 100,
        height: screenSize.height,
        left: 0,
        position: 'absolute',
        zIndex: -1,
        overflow: 'hidden',
        width: screenSize.width,
        pointerEvents: 'all',
      }}
    ></div>
  );
}

export default ParticlesComponent;
