import React from 'react';

const NewCircle = () => {
  return (
    <div className="ellipse-little-container">
      <span className="ellipse-2"></span>
      <span className="ellipse-3"></span>
      <span className="ellipse-3"></span>
      <span className="ellipse-3"></span>
      <span className="ellipse-3"></span>
      <span className="ellipse-3"></span>
      <span className="ellipse-3"></span>
      <span className="ellipse-3"></span>
      <span className="ellipse-3"></span>
    </div>
  );
};

export default NewCircle;
