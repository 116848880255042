import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BtnSimple from 'components/utils/btn/BtnSimple';

import './portfolio.css';

function PortfolioCard({ data, type }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const serviceType = '';
  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener('resize', () => {
        setWindowWidth(window.innerWidth);
      });
    };
  }, [setWindowWidth]);
  if (!data) {
    return null;
  }
  return (
    <Link
      to={'/portfolio/' + data.id}
      className={
        `portfolio-card pointer-all ${
          type === 'group' ? 'group' : windowWidth < 740 ? 'group' : type
        } ` + serviceType
      }
      style={{ background: data.color }}
    >
      <div className="portfolio-card-text">
        <h5 className="headline4">{data.title}</h5>
        <p className="paragraph">{data.description}</p>
      </div>
      <div className="portfolio-card-img">
        {data.background_1 && (
          <div className="portfolio-card-img-svg">
            <img
              loading="lazy"
              className={serviceType + ''}
              src={data.background_1}
              alt={data.title}
            />
          </div>
        )}
        <div className="portfolio-card-img-phone">
          {data.background_2 && (
            <img loading="lazy" src={data.background_2} alt="" className={'bg ' + serviceType} />
          )}
          <div className={'phone ' + serviceType}>
            <img src={data.cover} alt="pic" />
          </div>
        </div>
      </div>
      <span className="position-absolute">
        <BtnSimple>Смотреть проект</BtnSimple>
      </span>
    </Link>
  );
}

export default PortfolioCard;
