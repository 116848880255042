function ArrowSvg() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.92176 1.22739C1.92176 0.826205 2.24699 0.500977 2.64818 0.500977H10.6407H11.3652V1.17465C11.3664 1.19207 11.3671 1.20966 11.3671 1.22739V9.21799C11.3671 9.61917 11.0418 9.9444 10.6407 9.9444C10.2395 9.9444 9.91424 9.61918 9.91424 9.21799V2.98109L1.60726 11.2881C1.32358 11.5718 0.863634 11.5718 0.57995 11.2881C0.296267 11.0044 0.296267 10.5444 0.57995 10.2608L8.8869 1.95381H2.64818C2.24699 1.95381 1.92176 1.62858 1.92176 1.22739Z"
        fill="inherit"
      />
    </svg>
  );
}

export default ArrowSvg;
