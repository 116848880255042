import { useRoutes } from 'react-router-dom';
import IntroPage from 'components/intropage/Intropage';
// import FeedbackPage from 'components/feedbackPage/FeedbackPage';
// import Blog from 'components/blogPage/Blog';
// import Article from 'components/articlePage/Article';
import Project from 'components/project/Project';
import PorrtfoliosPage from 'components/portfolioPage/PortfoliosPage';
import ServicePage from 'components/servicePage/ServicePage';

export default function ThemeRoutes() {
  return useRoutes([MainRoutes]);
}

export const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      title: 'Главная',
      element: <IntroPage />,
    },
    // {
    //   path: 'feedback',
    //   title: 'Отзывы',
    //   element: <FeedbackPage />,
    // },
    // {
    //   path: 'blog',
    //   title: 'Блог',
    //   children: [
    //     {
    //       path: ':id',
    //       element: <Article />,
    //     },
    //     {
    //       path: '',
    //       element: <Blog />,
    //     },
    //   ],
    // },
    {
      path: 'portfolio',
      title: 'Портфолио',
      children: [
        {
          path: ':id',
          element: <Project />,
        },
        {
          path: '',
          element: <PorrtfoliosPage />,
        },
      ],
    },
    {
      path: 'contact',
      title: 'Контакты',
    },
    {
      path: 'services/:id',
      element: <ServicePage />,
    },
  ],
};
