import React from 'react';
import PortfolioCard from './PortfolioCard';
import BtnOutlineXl from 'components/utils/btn/BtnOutlineXL';
import { useGetProjectQuery } from 'store/reducers/portfolio';
import Circle from 'components/utils/Circle';

function Portfolio() {
  const { data: portfolios = [], isLoading } = useGetProjectQuery();

  if (isLoading) {
    return null;
  }
  return (
    <div className="portfolio content position-relative" id="portfolio">
      <div className="circle position-absolute">
        <Circle />
      </div>
      <div className="left-side portfolio-title">
        <h3 className="headline3 pointer-all">Портфолио</h3>
      </div>
      <div className="portfolio-rigt-side right-side">
        <div className="portfolio-cards">
          {[...portfolios]
            .sort((a, b) => a.index_number - b.index_number)
            .slice(0, 4)
            .map((item, i) => {
              const isOdd = portfolios.length % 2 === 1;
              const type =
                i === portfolios.length - 1 && isOdd
                  ? 'single'
                  : portfolios.length === 1
                    ? 'single'
                    : 'group';
              return <PortfolioCard key={item.id} data={item} type={type} />;
            })}
        </div>
        <div className="portfolio-link">
          <a href="/portfolio">
            <BtnOutlineXl>Смотреть все проекты</BtnOutlineXl>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
