import React from 'react';
import './project.css';
import 'components/intropage/portfolio/portfolio.css';
import { useParams } from 'react-router-dom';
import BtnOutlineXl from 'components/utils/btn/BtnOutlineXL.jsx';
import Swiper from 'components/utils/carousel/Swiper';
import { useGetProjectByIdQuery } from 'store/reducers/portfolio';
import { useGetServicesQuery } from 'store/reducers/serviceApi';

import { CircularProgress, Box } from '@mui/material';
import SwiperComponent from 'components/utils/carousel/Swiper';

function Project() {
  const { id } = useParams();

  const { data: project, isFetching } = useGetProjectByIdQuery(id);
  const { data: services, isFetching: isFetchingServices } = useGetServicesQuery();

  if (isFetching || isFetchingServices)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh" width="100%">
        <CircularProgress color="primary" />
      </Box>
    );
  if (!project) return null;

  return (
    <section className="project-page">
      <Box
        className="project-head-img pointer-all"
        sx={{
          backgroundColor: project.color,
          py: { xs: 3, md: 10 },
          borderRadius: { xs: 5, md: 10 },
          minHeight: { xs: '170px', md: '500px' },
        }}
        position="relative"
      >
        <img className="icon" src={project.background_1} alt="background_1" />
        <img
          className="bg"
          src={project.background_2 === null ? project.background_1 : project.background_2}
          alt="background_2"
        />
        <Box width={{ xs: '100%', md: '80%' }} className="phones" maxHeight="100%">
          {project.big_photos
            .map((item) => JSON.parse(item))
            .sort((a, b) => a.index_number - b.index_number)
            .map((item, index) => (
              <Box key={item.id} maxWidth={'100%'} height={'100%'}>
                <img loading="lazy" src={item.upload} alt="big photos" />
              </Box>
            ))}
        </Box>
      </Box>

      <div className="project-content">
        <h2 className={'headline2 '}>
          <span className="white">{project.title}</span>
        </h2>
        <div className="content">
          <div className="left-side">
            <h3 className="headline3 pointer-all">О проекте</h3>
          </div>
          <div className="sub_text right-side">
            <p className="quote" style={{ paddingTop: 0 }}>
              {project?.about}
            </p>
            {project?.about_content.split('\n').map((paragraph, index) => (
              <p className="paragraph pointer-all" key={index}>
                {paragraph}
              </p>
            ))}
          </div>
        </div>
        <div className="content project-carousel">
          <div className="left-side">
            <h6 className="pt-0 dsc1 pointer-all">Основные экраны</h6>
          </div>
          <div className="right-side">
            <Swiper
              bg={project.color}
              data={project.small_photos.map((item) => JSON.parse(item))}
            />
          </div>
        </div>
        <div className="project-solution content py-4">
          <div className="left-side">
            <h2 className="headline3 pointer-all pt-0">Решение</h2>
          </div>
          <div className="sub_text right-side">
            {project?.solution.split('\n').map((paragraph, index) => (
              <p
                className="paragraph pointer-all"
                key={index}
                style={{ marginTop: index === 0 ? 0 : '1rem' }}
              >
                {paragraph}
              </p>
            ))}
          </div>
        </div>
      </div>

      {!!project.blocks.length &&
        project.blocks.map((block) => {
          const service = services.find((service) => service.id === block.service);
          return (
            <span key={block.id}>
              <div className="content aboute-service">
                <div className="left-side"></div>
                <div className="right-side">
                  <h3 className="pt-0 headline3">
                    <span className="yellow pointer-all">{service?.title}</span>
                  </h3>
                  <p className="paragraph py-2 pointer-all">{service?.description}</p>
                  <div className="btn">
                    <BtnOutlineXl link={'/services/' + service?.id}>
                      Подробнее об услуге
                    </BtnOutlineXl>
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="left-side">
                  <h3 className="headline3 pointer-all">{block?.title}</h3>
                </div>
                <div className="sub_text right-side">
                  <p className="quote pt-0 pb-1 pointer-all">{block?.about}</p>
                  {block?.description.split('\n').map((paragraph, index) => (
                    <p
                      className="paragraph pointer-all pb-2"
                      key={index}
                      style={{ marginTop: index === 0 ? 0 : '1rem' }}
                    >
                      {paragraph}
                    </p>
                  ))}
                  {block.block_photos.length > 0 &&
                    (block.block_photos.length > 1 ? (
                      <SwiperComponent
                        data={block.block_photos.map((item) => JSON.parse(item))}
                        bg={project.color}
                      />
                    ) : (
                      block?.block_photos.map((item) => JSON.parse(item))[0].upload && (
                        <div className="admin pt-0 pointer-all">
                          <img
                            loading="lazy"
                            src={block?.block_photos.map((item) => JSON.parse(item))[0].upload}
                            alt="admin"
                          />
                        </div>
                      )
                    ))}
                </div>
              </div>
            </span>
          );
        })}
    </section>
  );
}

export default Project;
