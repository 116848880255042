import React from 'react';
import BtnSimple from '../../utils/btn/BtnSimple';
import { Link } from 'react-router-dom';

function ServiceCard({ id, title, text, svg = null }) {
  return (
    <div className="animate pointer-all">
      <Link to={'/services/' + id} className="cursor-pointer h-100">
        <div className={`table-card show`} style={{ transition: 'height .5s ease-in-out' }}>
          <div className="table-card-body d-flex h-100 cursor-pointer">
            <h4 className="table-card-body-title headline4">{title}</h4>
            <p className="table-card-body-about paragraph">{text}</p>
            <span className="d-flex align-items-center justify-content-center w-100 h-100">
              {svg}
            </span>

            <span className="position-absolute">
              <BtnSimple>Подробнее</BtnSimple>
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default ServiceCard;
