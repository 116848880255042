import React from 'react';
import './services.css';
import ServiceCard from './ServiceCard';
import { useGetServicesQuery } from 'store/reducers/serviceApi';
import NewCircle from 'components/utils/NewCircle';
function Services() {
  const { data: services = [] } = useGetServicesQuery();
  return (
    <div className="services" id="services">
      <h4 className="services-title headline3 pointer-all">Услуги</h4>
      <div className="services-container">
        <div className="grid-container">
          {services.map((item, index) => {
            const width = window.innerWidth;
            const svg =
              width > 1200 ? (
                index === 4 ? (
                  <NewCircle />
                ) : null
              ) : width > 600 ? (
                index === 1 ? (
                  <NewCircle />
                ) : null
              ) : null;
            return (
              <div className="grid-item" key={index}>
                <ServiceCard id={item.id} title={item.title} svg={svg} text={item.description} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Services;
