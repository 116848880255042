// style
import 'components/utils/typography/typography.css';

// components
import ScrollTop from 'components/utils/ScrollTop';
import Contact from 'components/contact/Contact';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import ParticlesComponent from 'components/PartclesComponent';
import Routes from './routes';
import Popup from 'components/utils/Popup';

function App() {
  return (
    <>
      <ScrollTop />
      <div className="App pointer-none">
        <Popup />
        <Header />
        <div className="container">
          <Routes />
          <Contact />
        </div>
        <ParticlesComponent />
        <Footer />
      </div>
    </>
  );
}

export default App;
