import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => {
        console.log(process.env.REACT_APP_API_URL);
        return 'site_user';
      },
    }),
  }),
});

export const { useGetUsersQuery } = usersApi;
export default usersApi;
