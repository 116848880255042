import React from 'react';
import NewCircle from './NewCircle';

const Circle = () => {
  return (
    <div className="ellipse-container">
      <span className="ellipse-1">
        <div></div>
      </span>
      <span className="ellipse-1"></span>
      <NewCircle />
    </div>
  );
};

export default Circle;
